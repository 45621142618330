import React from "react";
import {
  TouchableOpacity,
  Text,
  StyleSheet,
  ViewStyle,
  View,
  ActivityIndicator,
  TextStyle,
} from "@/ui/index";
import { Colors } from "@/constants";
import Icon, { icons } from "./Icon";
import { getLangDir } from "@/models/utils";

const isRTL = getLangDir() === "rtl";

const PrimaryButton = ({
  text,
  onPress,
  style,
  disabled = false,
  loading = false,
  textStyle = {},
  height = 40,
  icon,
}: {
  text: string;
  onPress: () => void;
  height?: number;
  disabled?: boolean;
  loading?: boolean;
  style?: ViewStyle;
  textStyle?: TextStyle;
  icon?: keyof typeof icons;
}) => {
  return (
    <View style={{ flexDirection: "row" }}>
      <TouchableOpacity
        disabled={disabled || loading}
        onPress={onPress}
        activeOpacity={0.5}
        style={StyleSheet.flatten([
          {
            ...s.container,
            height,
            backgroundColor: Boolean(disabled || loading)
              ? Colors.gray6
              : Colors.accent,
          },
          style || {},
        ])}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {!loading && icon && <Icon icon={icon} style={s.icon} />}
          {!loading && <Text style={{ ...s.text, ...textStyle }}>{text}</Text>}
          {loading && <ActivityIndicator size="small" color={Colors.white} />}
        </View>
      </TouchableOpacity>
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    borderRadius: 6,
    backgroundColor: Colors.accent,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 40,
    paddingHorizontal: 20,
  },
  text: {
    fontWeight: "500",
    color: Colors.white,
    fontSize: 14,
    lineHeight: 20,
  },
  icon: {
    marginRight: isRTL ? 0 : 8,
    marginLeft: isRTL ? 8 : 0,
    tintColor: Colors.white,
  },
});

export default PrimaryButton;
