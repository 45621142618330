import { View, Text, Pressable, StyleSheet } from "@/ui";
import { useUISlice } from "@/models/useUISlice";
import * as serverClient from "@/models/serverClient";
import { useRouter } from "next/router";
import { getLocalizedBasePath } from "@/models/utils";
import { datadogLogs } from "@datadog/browser-logs";
import ModalContainer from "./ModalContainer";
import PrimaryButton from "./PrimaryButton";
import Icon from "./Icon";
import { Colors, PRICE_MAP } from "@/constants";
import { useState } from "react";
import { PurchasePlan } from "@/api-lib";
import { useUserSlice } from "@/models/useUserSlice";
import { useTranslation } from "react-i18next";

const Paywall = () => {
  const alertWidgetVisible = useUISlice((state) => state.alertWidgetVisible);
  const router = useRouter();
  const [selectedPlan, setSelectedPlan] = useState<PurchasePlan>("UNLIMITED");
  const { t } = useTranslation();

  const _getPrices = () => {
    const country = useUserSlice.getState().user.country || "US";

    let price10Hours =
      PRICE_MAP["10_HOURS"][country] || PRICE_MAP["10_HOURS"]["US"];

    let priceUnlimited =
      PRICE_MAP["UNLIMITED"][country] || PRICE_MAP["UNLIMITED"]["US"];

    return {
      price10Hours: `${price10Hours} ${t("paywall.oneTime")}`,
      priceUnlimited: `${priceUnlimited} ${t("paywall.perYear")}`,
    };
  };

  const gotoCheckout = async () => {
    const response = await serverClient.getPaymentLinkAsync({
      baseAppUrl: `https://1transcribe.com${getLocalizedBasePath()}`,
      purchasePlan: selectedPlan,
    });

    if (response.data) {
      datadogLogs.logger.info("Funnel 9: Checkout", {
        deviceId: useUserSlice.getState().deviceId,
        purchasePlan: selectedPlan,
        userAccess: useUserSlice.getState().userAccess,
      });

      router.push(response.data.url);
    }
  };

  const renderPlan = ({
    title,
    description,
    price,
    discountText,
    onPress,
    plan,
  }: {
    title: string;
    description: string;
    price: string;
    discountText?: string;
    onPress: () => void;
    plan: PurchasePlan;
  }) => {
    const isSelected = selectedPlan === plan;
    return (
      <Pressable
        onPress={onPress}
        style={{
          ...s.planContainer,
          borderColor: isSelected ? Colors.accent : Colors.gray4,
        }}
      >
        <View style={{}}>
          <Text style={s.planTitle}>
            {title}{" "}
            {discountText && (
              <Text
                style={{
                  backgroundColor: Colors.error,
                  paddingHorizontal: 8,
                  color: Colors.white,
                  paddingVertical: 2,
                  fontSize: 18,
                  lineHeight: 32,
                  fontWeight: "600",
                }}
              >
                {discountText}
              </Text>
            )}
          </Text>
          <Text style={s.planPrice}>{price}</Text>
          <Text style={s.planDescription}>{description}</Text>
        </View>

        <View
          style={{
            ...s.checkbox,
            backgroundColor: isSelected ? Colors.accent : Colors.white,
            borderColor: isSelected ? Colors.accent : Colors.gray4,
          }}
        >
          <Icon icon="tick" style={s.checkboxIcon} />
        </View>
      </Pressable>
    );
  };

  if (alertWidgetVisible !== "paywall") return null;

  const { price10Hours, priceUnlimited } = _getPrices();

  return (
    <ModalContainer
      title={t("paywall.upgrade")}
      loading={false}
      subtitle={t("paywall.subtitle")}
      onDismiss={() => {
        useUISlice.getState().setAlertWidgetVisible(undefined);
      }}
      shouldPersistBackdropClick={true}
      primaryButtonText={t("paywall.upgrade")}
      hideCancel={true}
      modalWidth={360}
    >
      <View style={{ height: 16 }} />

      {renderPlan({
        title: t("paywall.tenHours"),
        description: t("paywall.tenHoursDescription"),
        price: price10Hours,
        onPress: () => {
          setSelectedPlan("10_HOURS");
        },
        plan: "10_HOURS",
      })}

      <View style={{ height: 16 }} />

      {renderPlan({
        title: t("paywall.unlimited"),
        description: t("paywall.unlimitedDescription"),
        price: priceUnlimited,
        onPress: () => {
          setSelectedPlan("UNLIMITED");
        },
        plan: "UNLIMITED",
      })}

      <View style={{ height: 16 }} />

      <PrimaryButton
        text={t("paywall.pay", {
          price: selectedPlan === "10_HOURS" ? price10Hours : priceUnlimited,
        })}
        height={56}
        textStyle={{ fontSize: 18 }}
        onPress={gotoCheckout}
      />

      <View style={{ marginBottom: -16 }} />
    </ModalContainer>
  );
};

const s = StyleSheet.create({
  checkbox: {
    width: 18,
    height: 18,
    borderWidth: 1.5,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    borderColor: Colors.subtitle,
  },
  checkboxIcon: {
    tintColor: Colors.white,
    width: 12,
    height: 12,
  },
  planContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderWidth: 1.5,
    borderRadius: 6,
    padding: 12,
  },
  planTitle: {
    fontWeight: "600",
    fontSize: 18,
    lineHeight: 24,
    color: Colors.text,
  },
  planPrice: {
    fontSize: 14,
    color: Colors.text,
    lineHeight: 22,
  },
  planDescription: {
    fontSize: 14,
    color: Colors.subtitle,
    lineHeight: 22,
  },
});

export default Paywall;
