import React from "react";
import { View } from "@/ui/index";
import { useUISlice } from "@/models/useUISlice";
import ModalContainer from "@/ui/ModalContainer";
import dynamic from "next/dynamic";
import { useTranslation } from "react-i18next";

const Recorder = dynamic(() => import("@/ui/Recorder"), { ssr: false });

const AlertDashboardRecorder = () => {
  const alertWidgetVisible = useUISlice((state) => state.alertWidgetVisible);
  const { t } = useTranslation();

  if (alertWidgetVisible !== "recorder") return null;

  const _onRecordingComplete = (file: File, duration: number) => {
    useUISlice.getState().setAlertWidgetVisible(undefined);
    (file as any).duration = duration;
    useUISlice.getState().setAlertStartTranscription(file);
  };

  return (
    <ModalContainer
      title={t("alertDashboardRecorder.title")}
      onDismiss={() => useUISlice.getState().setAlertWidgetVisible(undefined)}
      primaryButtonText={t("alertDashboardRecorder.recordButton")}
      shouldPersistBackdropClick
      hideCancel
      modalWidth={360}
    >
      <Recorder onRecordingComplete={_onRecordingComplete} />
      <View style={{ marginBottom: -16 }} />
    </ModalContainer>
  );
};

export default AlertDashboardRecorder;
