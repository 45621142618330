import React, { useEffect, useRef } from "react";
import { StyleSheet, Text, View } from "@/ui/index";
import { Colors } from "@/constants";
import PrimaryButton from "@/ui/PrimaryButton";
import Icon from "@/ui/Icon";

const ModalContainer = (props: {
  title: string;
  onDismiss: () => void;
  onPrimaryButtonPress?: () => void;
  primaryButtonText?: string;
  children: React.ReactNode;
  modalWidth: any;
  modalMaxWidth?: number;
  subtitle?: string;
  primaryButtonDisabled?: boolean;
  destructive?: boolean;
  cancelText?: string;
  hideCancel?: boolean;
  shouldPersistBackdropClick?: boolean;
  loading?: boolean;
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (props.shouldPersistBackdropClick) return;

      if (ref.current && !ref.current.contains(event.target)) {
        props.onDismiss();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <View style={{ position: "fixed", ...s.container } as any}>
      <View ref={ref} style={s.wrapper}>
        <View
          style={{
            borderRadius: 8,
            paddingHorizontal: 24,
            paddingVertical: 16,
            backgroundColor: Colors.white,
            width: props.modalWidth,
            maxWidth: props.modalMaxWidth || undefined,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Text numberOfLines={1} style={s.title}>
              {props.title}
            </Text>

            <Icon
              icon="close"
              onPress={props.onDismiss}
              containerStyle={{ margin: 0, padding: 0, width: 32, height: 32 }}
              style={{ width: 14, height: 14, tintColor: Colors.text }}
            />
          </View>

          {props.subtitle && <Text style={s.subtitle}>{props.subtitle}</Text>}

          <View>{props.children}</View>

          <View style={s.buttonContainer}>
            {!props.hideCancel && (
              <PrimaryButton
                style={{ backgroundColor: Colors.gray4 }}
                textStyle={{ color: Colors.text }}
                text={props.cancelText || "Cancel"}
                onPress={props.onDismiss}
              />
            )}

            <View style={{ width: 16 }} />

            {props.onPrimaryButtonPress && (
              <PrimaryButton
                loading={props.loading}
                style={{
                  minWidth: 130,
                  backgroundColor: props.destructive
                    ? Colors.error
                    : Colors.accent,
                }}
                disabled={Boolean(props.primaryButtonDisabled)}
                text={props.primaryButtonText}
                onPress={props.onPrimaryButtonPress}
              />
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    zIndex: 100,
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.65)",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  title: {
    lineHeight: 28,
    fontSize: 18,
    fontWeight: "600",
  },
  subtitle: {
    marginTop: 8,
    lineHeight: 20,
    fontSize: 14,
    color: Colors.subtitle,
  },
  buttonContainer: {
    marginTop: 32,
    alignItems: "center",
    alignSelf: "flex-end",
    flexDirection: "row",
  },
  textInput: {
    fontSize: 16,
    lineHeight: 22,
    color: Colors.text,
    padding: 16,
    height: 200,
    backgroundColor: Colors.white,
    borderRadius: 8,
    borderColor: Colors.gray4,
    borderWidth: 1.5,
  },
});

export default ModalContainer;
