export * from "./Colors";
export * from "./transcriptionOptions";

export const PRICE_MONTHLY = 8.99;
export const PRICE_YEARLY = 79.99;

export const PRICE_MAP = {
  ["10_HOURS"]: {
    US: "US$8.99",
    BR: "R$49.99",
    CA: "CA$11.99",
    CO: "COP 35,000",
    IT: "€8.99",
    ES: "€8.99",
    GB: "£6.99",
    PE: "PEN 29.99",
  },
  ["UNLIMITED"]: {
    US: "US$79.99",
    BR: "R$399",
    CA: "CA$99.99",
    CO: "COP 329,000",
    IT: "€69.99",
    ES: "€69.99",
    GB: "£59.99",
    PE: "PEN 299",
  },
};

export const AVAILABLE_LOCALES = ["ar", "en", "es", "it", "pt"];

export const DESKTOP_WIDTH = 1200;

export const SUPPORTED_FILE_EXTENSIONS = [
  ".3ga",
  ".webm",
  ".8svx",
  ".mts",
  ".m2ts",
  ".ts",
  ".aac",
  ".mov",
  ".ac3",
  ".mp2",
  ".aif",
  ".mp4",
  ".m4v",
  ".aiff",
  ".mxf",
  ".alac",
  ".amr",
  ".ape",
  ".au",
  ".dss",
  ".flac",
  ".flv",
  ".m4a",
  ".m4b",
  ".m4p",
  ".m4r",
  ".mp3",
  ".mpga",
  ".ogg",
  ".oga",
  ".mogg",
  ".opus",
  ".qcp",
  ".tta",
  ".voc",
  ".wav",
  ".wma",
  ".wv",
];

export const __DEV__ =
  Boolean(process) && process.env.NODE_ENV === "development";
