import React from "react";
import { View } from "@/ui/index";
import { useUISlice } from "@/models/useUISlice";
import ModalContainer from "@/ui/ModalContainer";

const AlertModal = () => {
  const modal = useUISlice((state) => state.alertModal);
  const setModal = useUISlice((state) => state.setAlertModal);

  if (!modal) return null;

  return (
    <ModalContainer
      title={modal.title}
      loading={modal.loading}
      subtitle={modal.subtitle}
      onDismiss={modal.onDismiss || (() => setModal(undefined))}
      shouldPersistBackdropClick={modal.shouldPersistBackdropClick}
      primaryButtonText={modal.button.text}
      onPrimaryButtonPress={modal.button.onPress}
      hideCancel={!modal.cancelText}
      cancelText={modal.cancelText}
      destructive={modal.button.destructive}
      modalWidth={360}
    >
      <View />
    </ModalContainer>
  );
};

export default AlertModal;
