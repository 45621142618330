export const audioLanguages: { value: LanguageCode; label: string }[] = [
  { value: "en", label: "🇺🇸 English" },
  { value: "es", label: "🇪🇸 Español" },
  { value: "it", label: "🇮🇹 Italiano" },
  { value: "ar", label: "🇸🇦 العربية" },
  { value: "fr", label: "🇫🇷 Français" },
  { value: "nl", label: "🇳🇱 Nederlands" },
  { value: "de", label: "🇩🇪 Deutsch" },
  { value: "zh", label: "🇨🇳 中文 (普通话)" },
  { value: "hi", label: "🇮🇳 हिन्दी" },
  { value: "pt", label: "🇵🇹 Português" },
  { value: "ru", label: "🇷🇺 Русский" },
  { value: "ja", label: "🇯🇵 日本語" },
  { value: "ko", label: "🇰🇷 한국어" },
  { value: "tr", label: "🇹🇷 Türkçe" },
  { value: "pl", label: "🇵🇱 Polski" },
  { value: "vi", label: "🇻🇳 Tiếng Việt" },
  { value: "th", label: "🇹🇭 ไทย" },
  { value: "id", label: "🇮🇩 Bahasa Indonesia" },
  { value: "uk", label: "🇺🇦 Українська" },
  { value: "sv", label: "🇸🇪 Svenska" },
  { value: "ro", label: "🇷🇴 Română" },
  { value: "el", label: "🇬🇷 Ελληνικά" },
  { value: "cs", label: "🇨🇿 Čeština" },
  { value: "fi", label: "🇫🇮 Suomi" },
  { value: "da", label: "🇩🇰 Dansk" },
  { value: "no", label: "🇳🇴 Norsk" },
  { value: "he", label: "🇮🇱 עברית" },
  { value: "hu", label: "🇭🇺 Magyar" },
  { value: "bn", label: "🇧🇩 বাংলা" },
  { value: "ms", label: "🇲🇾 Bahasa Melayu" },
  { value: "ur", label: "🇵🇰 اردو" },
  { value: "fa", label: "🇮🇷 فارسی" },
  { value: "sw", label: "🇹🇿 Kiswahili" },
  { value: "tl", label: "🇵🇭 Tagalog" },
  { value: "ta", label: "🇮🇳 தமிழ்" },
  { value: "mr", label: "🇮🇳 मराठी" },
  { value: "te", label: "🇮🇳 తెలుగు" },
  { value: "jw", label: "🇮🇩 Basa Jawa" },
  { value: "gu", label: "🇮🇳 ગુજરાતી" },
  { value: "kn", label: "🇮🇳 ಕನ್ನಡ" },
  { value: "ml", label: "🇮🇳 മലയാളം" },
  { value: "si", label: "🇱🇰 සිංහල" },
  { value: "km", label: "🇰🇭 ភាសាខ្មែរ" },
  { value: "lo", label: "🇱🇦 ພາສາລາວ" },
  { value: "my", label: "🇲🇲 မြန်မာစာ" },
  { value: "ne", label: "🇳🇵 नेपाली" },
  { value: "pa", label: "🇵🇰 پنجابی" },
  { value: "sd", label: "🇵🇰 سنڌي" },
  { value: "af", label: "🇿🇦 Afrikaans" },
  { value: "am", label: "🇪🇹 አማርኛ" },
  { value: "as", label: "🇮🇳 অসমীয়া" },
  { value: "az", label: "🇦🇿 Azərbaycan dili" },
  { value: "ba", label: "🇷🇺 Башҡорт теле" },
  { value: "be", label: "🇧🇾 Беларуская" },
  { value: "bg", label: "🇧🇬 Български" },
  { value: "bo", label: "🇨🇳 བོད་ཡིག" },
  { value: "br", label: "🇫🇷 Brezhoneg" },
  { value: "bs", label: "🇧🇦 Bosanski" },
  { value: "ca", label: "🇪🇸 Català" },
  { value: "cy", label: "🏴󠁧󠁢󠁷󠁬󠁳󠁿 Cymraeg" },
  { value: "et", label: "🇪🇪 Eesti" },
  { value: "eu", label: "🇪🇸 Euskara" },
  { value: "fo", label: "🇫🇴 Føroyskt" },
  { value: "gl", label: "🇪🇸 Galego" },
  { value: "ha", label: "🇳🇬 Hausa" },
  { value: "haw", label: "🇺🇸 ʻŌlelo Hawaiʻi" },
  { value: "hr", label: "🇭🇷 Hrvatski" },
  { value: "ht", label: "🇭🇹 Kreyòl Ayisyen" },
  { value: "hy", label: "🇦🇲 Հայերեն" },
  { value: "is", label: "🇮🇸 Íslenska" },
  { value: "ka", label: "🇬🇪 ქართული" },
  { value: "kk", label: "🇰🇿 Қазақ тілі" },
  { value: "la", label: "🇻🇦 Latina" },
  { value: "lb", label: "🇱🇺 Lëtzebuergesch" },
  { value: "ln", label: "🇨🇩 Lingála" },
  { value: "lt", label: "🇱🇹 Lietuvių" },
  { value: "lv", label: "🇱🇻 Latviešu" },
  { value: "mg", label: "🇲🇬 Malagasy" },
  { value: "mi", label: "🇳🇿 Māori" },
  { value: "mk", label: "🇲🇰 Македонски" },
  { value: "mn", label: "🇲🇳 Монгол" },
  { value: "mt", label: "🇲🇹 Malti" },
  { value: "nn", label: "🇳🇴 Nynorsk" },
  { value: "oc", label: "🇫🇷 Occitan" },
  { value: "ps", label: "🇦🇫 پښتو" },
  { value: "sa", label: "🇮🇳 संस्कृतम्" },
  { value: "sk", label: "🇸🇰 Slovenčina" },
  { value: "sl", label: "🇸🇮 Slovenščina" },
  { value: "sn", label: "🇿🇼 ChiShona" },
  { value: "so", label: "🇸🇴 Soomaali" },
  { value: "sq", label: "🇦🇱 Shqip" },
  { value: "sr", label: "🇷🇸 Српски" },
  { value: "su", label: "🇮🇩 Basa Sunda" },
  { value: "tg", label: "🇹🇯 Тоҷикӣ" },
  { value: "tk", label: "🇹🇲 Türkmen" },
  { value: "tt", label: "🇷🇺 Татар теле" },
  { value: "uz", label: "🇺🇿 Oʻzbek" },
  { value: "yi", label: "🇮🇱 ייִדיש" },
  { value: "yo", label: "🇳🇬 Yorùbá" },
  { value: "yue", label: "🇭🇰 粵語" },
];

export const numberOfSpeakers = [
  { value: 2, label: "2 Speakers" },
  { value: 3, label: "3 Speakers" },
  { value: 4, label: "4 Speakers" },
  { value: 5, label: "5 Speakers" },
  { value: undefined, label: "Detect automatically" },
];

export type LanguageCode =
  | "af"
  | "am"
  | "ar"
  | "as"
  | "az"
  | "ba"
  | "be"
  | "bg"
  | "bn"
  | "bo"
  | "br"
  | "bs"
  | "ca"
  | "cs"
  | "cy"
  | "da"
  | "de"
  | "el"
  | "en"
  | "es"
  | "et"
  | "eu"
  | "fa"
  | "fi"
  | "fo"
  | "fr"
  | "gl"
  | "gu"
  | "ha"
  | "haw"
  | "he"
  | "hi"
  | "hr"
  | "ht"
  | "hu"
  | "hy"
  | "id"
  | "is"
  | "it"
  | "ja"
  | "jw"
  | "ka"
  | "kk"
  | "km"
  | "kn"
  | "ko"
  | "la"
  | "lb"
  | "ln"
  | "lo"
  | "lt"
  | "lv"
  | "mg"
  | "mi"
  | "mk"
  | "ml"
  | "mn"
  | "mr"
  | "ms"
  | "mt"
  | "my"
  | "ne"
  | "nl"
  | "nn"
  | "no"
  | "oc"
  | "pa"
  | "pl"
  | "ps"
  | "pt"
  | "ro"
  | "ru"
  | "sa"
  | "sd"
  | "si"
  | "sk"
  | "sl"
  | "sn"
  | "so"
  | "sq"
  | "sr"
  | "su"
  | "sv"
  | "sw"
  | "ta"
  | "te"
  | "tg"
  | "th"
  | "tk"
  | "tl"
  | "tr"
  | "tt"
  | "uk"
  | "ur"
  | "uz"
  | "vi"
  | "yi"
  | "yo"
  | "zh"
  | "yue";
