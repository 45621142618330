import React from "react";
import { Pressable, Text, TextStyle } from "@/ui";
import { Colors } from "@/constants";

const TextLink = ({
  text,
  onPress,
  fontSize = 16,
  style = {},
  disabled = false,
}: {
  disabled?: boolean;
  fontSize?: number;
  onPress: () => void;
  text: string;
  style?: TextStyle;
}) => {
  return (
    <Pressable
      onPress={onPress}
      style={{ cursor: "pointer" }}
      disabled={disabled}
    >
      <Text
        style={{
          fontSize: fontSize,
          lineHeight: 32,
          color: Colors.link,
          textDecorationLine: "underline",
          textDecorationStyle: "dotted",
          ...style,
        }}
      >
        {text}
      </Text>
    </Pressable>
  );
};

export default TextLink;
