import React from "react";
import { View } from "@/ui/index";
import { useUISlice } from "@/models/useUISlice";
import ModalContainer from "@/ui/ModalContainer";
import Checkbox from "./Checkbox";
import PrimaryButton from "./PrimaryButton";
import { useTranslation } from "react-i18next";

const AlertTranscriptOptions = () => {
  const transcriptOptions = useUISlice((state) => state.transcriptOptions);

  const [withTimestamps, setWithTimestamps] = React.useState(false);
  const [withSpeakers, setWithSpeakers] = React.useState(false);
  const [withMonologue, setWithMonologue] = React.useState(false);
  const { t } = useTranslation();

  if (!transcriptOptions) return null;

  const _onDismiss = () => {
    setWithTimestamps(false);
    setWithSpeakers(false);
    setWithMonologue(false);
    useUISlice.getState().setTranscriptOptions(undefined);
  };

  return (
    <ModalContainer
      title={t("alertTranscriptOptions.title")}
      subtitle={t("alertTranscriptOptions.subtitle")}
      onDismiss={_onDismiss}
      hideCancel
      modalWidth={360}
    >
      <View style={{ height: 16 }} />
      <Checkbox
        title={t("alertTranscriptOptions.includeTimestamps")}
        containerStyle={{ marginBottom: 16 }}
        value={withTimestamps}
        onCheckboxChange={(value) => {
          setWithTimestamps(value);
          setWithMonologue(false);
        }}
      />

      {transcriptOptions.hasSpeakers && (
        <>
          <Checkbox
            title={t("alertTranscriptOptions.includeSpeakers")}
            containerStyle={{ marginBottom: 16 }}
            value={withSpeakers}
            onCheckboxChange={(value) => {
              setWithSpeakers(value);
              value && setWithMonologue(false);
            }}
          />
        </>
      )}

      <Checkbox
        title={t("alertTranscriptOptions.exportAsMonologue")}
        containerStyle={{ marginBottom: 32 }}
        value={withMonologue}
        onCheckboxChange={(value) => {
          setWithMonologue(value);

          if (value) {
            setWithSpeakers(false);
            setWithTimestamps(false);
          }
        }}
      />

      <PrimaryButton
        text={t("alertTranscriptOptions.exportButton")}
        onPress={() => {
          transcriptOptions.onExport({
            exportType: transcriptOptions.exportType,
            withTimestamps,
            withSpeakers,
            withMonologue,
          });
          _onDismiss();
        }}
      />
      <View style={{ marginBottom: -24 }} />
    </ModalContainer>
  );
};

export default AlertTranscriptOptions;
