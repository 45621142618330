import React, { useEffect, useState } from "react";
import { Pressable, Text, View } from "@/ui/index";
import TextLink from "@/ui/TextLink";
import { Colors } from "@/constants";
import { datadogLogs } from "@datadog/browser-logs";
import { Linking } from "react-native-web";
import { getMobileOperatingSystem } from "@/models/utils";
import Icon from "@/ui/Icon";
import { useUserSlice } from "@/models/useUserSlice";
import { useTranslation } from "react-i18next";

const DownloadApp = () => {
  const [visible, setVisible] = useState(false);
  const device = getMobileOperatingSystem();
  const { t } = useTranslation();

  const deviceId = useUserSlice.getState().deviceId;

  useEffect(() => {
    if (useUserSlice.getState().downloadAppLaunched) return;

    setVisible(device === "Android");
    useUserSlice.getState().setDownloadAppLaunched(true);
  }, []);

  const _onButtonClick = async () => {
    const deviceId = useUserSlice.getState().deviceId;

    datadogLogs.logger.info(
      `DownloadApp - clicked on ${device} download link`,
      { deviceId }
    );

    Linking.openURL(
      "https://play.google.com/store/apps/details?id=com.dktlabs.anytranscribe"
    );
  };

  const _onCancel = async () => {
    setVisible(false);

    datadogLogs.logger.info(
      `DownloadApp - dismissed ${device} download dialog`,
      { deviceId }
    );
  };

  if (!visible) return null;

  return (
    <View
      style={
        {
          position: "fixed",
          zIndex: 100,
          top: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,0.65)",
          width: "100%",
          height: "100%",
          // alignItems: "center",
          justifyContent: "flex-end",
        } as any
      }
    >
      <View
        style={{
          backgroundColor: "white",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: 32,
          paddingBottom: 50,
        }}
      >
        <Text
          style={{
            fontSize: 18,
            fontWeight: "600",
            textAlign: "center",
            color: Colors.text,
          }}
        >
          {t("downloadApp.availableOn")} {t("downloadApp.playStore")}
        </Text>

        <Pressable onPress={_onButtonClick}>
          <Icon
            icon="playStore"
            style={{
              height: 80,
              width: 240,
              resizeMode: "contain",
              marginTop: 8,
              marginBottom: 8,
            }}
          />
        </Pressable>

        <TextLink onPress={_onCancel} text={t("downloadApp.useWebVersion")} />
      </View>
    </View>
  );
};

export default DownloadApp;
