import React, { useEffect, useState } from "react";
import { View } from "@/ui/index";
import { useUISlice } from "@/models/useUISlice";
import Input from "@/ui/Input";
import ModalContainer from "@/ui/ModalContainer";

const AlertPrompt = () => {
  const prompt = useUISlice((state) => state.alertPrompt);
  const setPrompt = useUISlice((state) => state.setAlertPrompt);
  const [text, setText] = useState("");

  useEffect(() => {
    if (!prompt) return;

    setText(prompt.defaultValue || "");
  }, [prompt]);

  if (!prompt) return null;

  const _onPress = () => {
    prompt.button.onPress(text);
  };

  return (
    <ModalContainer
      title={prompt.title}
      subtitle={prompt.subtitle}
      onDismiss={() => setPrompt(undefined)}
      primaryButtonText={prompt.button.text}
      onPrimaryButtonPress={_onPress}
      loading={prompt.loading}
      destructive={prompt.button.destructive}
      modalWidth={360}
    >
      <View style={{ height: 16 }} />
      <Input
        autoFocus
        multiLine={prompt.multiLine}
        value={text}
        placeholder={prompt.placeholder}
        onTextChange={(text) => setText(text)}
      />
    </ModalContainer>
  );
};

export default AlertPrompt;
