import React, { useState } from "react";
import { Text, TextInput, TextInputStyle, View } from "@/ui/index";
import { Colors } from "@/constants";

const Input = ({
  type = "text",
  placeholder,
  label,
  value,
  onTextChange,
  disabled = false,
  autoFocus = false,
  multiLine = false,
}: {
  type?: "text" | "password";
  placeholder?: string;
  label?: string;
  value: string;
  onTextChange: (text: string) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  multiLine?: boolean;
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <View>
      {label && (
        <Text
          style={{
            fontWeight: "300",
            fontSize: 13,
            color: Colors.subtitle,
            marginBottom: 4,
          }}
        >
          {label}
        </Text>
      )}
      <TextInput
        autoCorrect={false}
        autoFocus={autoFocus}
        multiline={multiLine}
        onChangeText={onTextChange}
        value={value}
        secureTextEntry={type === "password"}
        placeholder={placeholder}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        placeholderTextColor={Colors.subtitle}
        editable={!disabled}
        style={
          {
            outlineStyle: "none",
            borderColor: Boolean(focused && !disabled)
              ? Colors.text
              : Colors.gray4,
            borderRadius: 6,
            borderWidth: 1,
            height: multiLine ? 180 : 44,
            paddingLeft: 12,
            fontSize: 16,
            backgroundColor: disabled ? Colors.gray2 : Colors.white,
            color: Colors.text,
            lineHeight: multiLine ? 24 : undefined,
          } as TextInputStyle
        }
      />
    </View>
  );
};

export default Input;
